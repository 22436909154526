import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Layout,
  Button,
  Menu,
  Modal,
  Dropdown,
  Avatar,
  Typography,
  Badge,
  message,
} from "antd";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import actions from "redux/auth/actions";
import adminvendorprofileAction from "redux/admin/adminvendorprofile/actions";
import {
  ExclamationCircleOutlined,
  UserOutlined,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import SignupModal from "containers/Layouts/SignupModal";
import SigninModal from "containers/Layouts/SigninModal";
import ForgotModal from "containers/Layouts/ForgotModal";
import OTP from "containers/Layouts/OTP";
import {
  getLocalDataType,
  getLocalData,
  getLocaleMessages,
} from "redux/helper";
import { history, store } from "redux/store";
import layoutActions from "redux/Layout/actions";
import ListingLayoutAction from "redux/ListingLayout/actions";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import logoClolorPng from "../../assets/img/logo-clolor.png";
const { Text } = Typography;
const Header = (props) => {
  Geocode.setApiKey("AIzaSyD2fIwEEQ7r4n9OSVvOBMblCVCxfz23aro");
  Geocode.enableDebug();
  const dispatch = useDispatch();
  const location = useLocation();
  const formatted_address = localStorage.getItem("formatted_address");
  const storageLocation = JSON.parse(localStorage.getItem("currentLocation"));
  const {
    isserviceaccepted,
    isstaffaccepted,
    isprofileaccepted,
    notificationData,
  } = useSelector((state) => state.AdminVendorProfile);
  const LayoutListing = useSelector((state) => state.ListingLayout);
  const { locationName } = LayoutListing;
  const { saloonNameList } = useSelector((state) => state.Layouts);
  const { loader, isLoggedIn, isOtp, isemail } = useSelector(
    (state) => state.Auth
  );
  const [notificationlist, setnotificationlist] = useState([]);
  const [notificationcount, setnotificationcount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLVisible, setIsModalLVisible] = useState(false);
  const [isForgotVisible, setIsForgotVisible] = useState(false);
  const [isOtpVisible, SetIsOtpVisible] = useState(false);
  const [isCurrentLocation, SetIsCurrentLocation] = useState(false);
  const [OTPdata, setOTPdata] = useState("");

  const CurrentLocationOffFun = () => {
    SetIsCurrentLocation(!isCurrentLocation);
  };

  useEffect(() => {
    if (getLocalDataType() === "admin") {
      dispatch({
        type: adminvendorprofileAction.GET_ALL_NOTIFICATION,
        payload: {
          userid: 1,
          usertype: "administration",
        },
      });
    }
    if (getLocalDataType() === "vendor") {
      dispatch({
        type: adminvendorprofileAction.GET_ALL_NOTIFICATION,
        payload: {
          userid: getLocalData("id"),
          usertype: "vendor",
        },
      });
      dispatch({
        type: adminvendorprofileAction.GET_SINGLE_VENDOR,
        value: getLocalData("id"),
      });
    }
    if (getLocalDataType() === "user") {
      dispatch({
        type: adminvendorprofileAction.GET_ALL_NOTIFICATION,
        payload: {
          userid: getLocalData("id"),
          usertype: "user",
        },
      });
    }
  }, []);

  const changeadminnotifiaction = (usertype) => {
    if (usertype == "admin") {
      dispatch({
        type: adminvendorprofileAction.CLEAR_ALL_NOTIFICATION,
        payload: {
          userid: 1,
          usertype: "administration",
        },
      });
    }
    if (usertype == "vendor") {
      dispatch({
        type: adminvendorprofileAction.CLEAR_ALL_NOTIFICATION,
        payload: {
          userid: getLocalData("id"),
          usertype: "vendor",
        },
      });
    }
  };

  useEffect(() => {
    if (notificationData.length) {
      setnotificationlist(notificationData[0]);
      let countArr = notificationData[0].filter((item, i) => {
        return item.isviewed != 1;
      });
      setnotificationcount(countArr.length);
    }
  }, [notificationData]);

  useEffect(() => {
    if (isOtpVisible) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [isOtpVisible]);
  useEffect(() => {}, [saloonNameList]);

  useEffect(() => {
    store.dispatch({
      type: layoutActions.GET_LAYOUT_CATEGORIES,
    });
    if (isOtp) {
      setIsModalVisible(false);
      SetIsOtpVisible(isOtp);
    } else {
      SetIsOtpVisible(false);
      setOTPdata("");
    }
  }, [isOtp]);

  const showModal = ({ signupModalVisible }) => {
    setIsModalVisible(signupModalVisible);
  };

  const showLModal = ({ loginModalVisible }) => {
    setIsModalLVisible(loginModalVisible);
  };

  const searchLocation = (place) => {
    if (place?.name !== undefined) {
      console.log(place);
    } else {
      const data = {};
      data["latitude"] = place.geometry.location.lat();
      data["longitude"] = place.geometry.location.lng();
      data["vendoraddress"] = place.address_components[0].long_name;
      data["categoryid"] =
        location?.search !== null && location.search !== undefined
          ? Number(location?.search?.match(/\d+/g))
          : "";
      store.dispatch({
        type: ListingLayoutAction.GET_LOGIN_LISTING_SALOON,
        payload: data,
        initialLoader: true,
      });
      dispatch({
        type: ListingLayoutAction.GET_CURRENT_LATLONG,
        payload: [
          {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          },
        ],
      });
      localStorage.setItem("formatted_address", place.formatted_address);
      localStorage.setItem("cityName", place.address_components[0].long_name);
      if (location?.pathname !== getLocaleMessages({ id: "ListingBlank" })) {
        history.push(getLocaleMessages({ id: "ListingBlank" }));
      }
    }
  };

  const GetGeoLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        const data = {};
        data["latitude"] = latitude;
        data["longitude"] = longitude;
        data["categoryid"] =
          location?.search !== null && location.search !== undefined
            ? Number(location?.search?.match(/\d+/g))
            : "";
        store.dispatch({
          type: ListingLayoutAction.GET_LOGIN_LISTING_SALOON,
          payload: data,
          initialLoader: true,
        });
        dispatch({
          type: ListingLayoutAction.GET_CURRENT_LATLONG,
          payload: [
            {
              latitude: latitude,
              longitude: longitude,
            },
          ],
        });
        Geocode.fromLatLng(latitude, longitude).then(
          (response) => {
            let city, state, country;
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = response.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country =
                      response.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            localStorage.setItem(
              "formatted_address",
              city + " ," + state + " ," + country
            );
          },
          (error) => {
            localStorage.setItem("formatted_address", "");
          }
        );
      },
      (error) => {
        localStorage.setItem("formatted_address", "");
        store.dispatch({
          type: ListingLayoutAction.GET_LOGIN_LISTING_SALOON,
          payload: { latitude: 0, longitude: 0 },
          initialLoader: true,
        });
      }
    );
  };
  useEffect(() => {
    if (storageLocation !== null) {
      if (location?.pathname === getLocaleMessages({ id: "ListingBlank" })) {
        if (
          storageLocation !== undefined &&
          Object.keys(storageLocation)?.length > 0 &&
          storageLocation?.geometry !== undefined
        ) {
          let data = {};
          data["latitude"] = storageLocation?.geometry?.location?.lat;
          data["longitude"] = storageLocation?.geometry?.location?.lng;
          data["vendoraddress"] =
            storageLocation?.address_components[0]?.long_name;
          data["categoryid"] =
            location?.search !== null && location?.search !== undefined
              ? Number(location?.search?.match(/\d+/g))
              : "";
          store.dispatch({
            type: ListingLayoutAction.GET_LOGIN_LISTING_SALOON,
            payload: data,
            initialLoader: true,
          });
          dispatch({
            type: ListingLayoutAction.GET_CURRENT_LATLONG,
            payload: [
              {
                latitude: storageLocation?.geometry?.location?.lat,
                longitude: storageLocation?.geometry?.location?.lng,
              },
            ],
          });
          localStorage.setItem(
            "formatted_address",
            storageLocation.formatted_address
          );
        } else {
          GetGeoLocation();
        }
      }
    } else {
      GetGeoLocation();
    }
  }, [location.pathname]);

  const fOk = () => {
    setIsForgotVisible(false);
  };

  const LoginForgot = ({ login, forgot }) => {
    if (forgot) {
      setIsModalLVisible(login);
      setIsForgotVisible(forgot);
    } else if (login) {
      setIsForgotVisible(forgot);
      setIsModalLVisible(login);
    }
  };

  const LoginSignup = ({ login, signup }) => {
    if (login) {
      setIsModalVisible(signup);
      setIsModalLVisible(login);
    } else if (signup) {
      setIsModalLVisible(login);
      setIsModalVisible(signup);
    }
  };

  const otpOk = () => {
    Modal.confirm({
      title: "Warning",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to close this modal and the otp modal never open",
      okText: "ok",
      cancelText: "cancel",
      maskClosable: true,
      mask: true,
      onOk: () => {
        dispatch({
          type: actions.VERIFY_OTP_SUCCESS,
        });
      },
    });
  };

  const onFinish = (values) => {
    values = {
      ...values,
      ...values["mobile_number"],
      confirmpassword: values["password"],
      cityid: 1,
      countryid: 1,
    };
  };

  const onFinishLogin = (values) => {
    dispatch({
      type: actions.AUTHENTICATE_USER,
      payload: values,
      callBackAction: () => {
        showLModal({ loginModalVisible: false });
      },
    });
  };

  const onFinishForgot = (values) => {
    dispatch({
      type: actions.SEND_PASSWORD_RESET_LINK,
      payload: values,
      callBackAction: () => {
        LoginForgot({
          login: true,
          forgot: false,
        });
      },
    });
  };

  const onFinishOTP = () => {
    if (OTPdata.length === 5) {
      dispatch({
        type: actions.VERIFY_OTP,
        payload: {
          otp: OTPdata,
          email: isemail,
        },
        callBackAction: (status) => {
          if (status) {
            SetIsOtpVisible(false);
            setOTPdata("");
          }
        },
      });
    } else {
      message.error(getLocaleMessages({ id: "please enter valid OTP" }));
    }
  };
  const onChangeOTP = (value) => {
    setOTPdata(value);
  };

  const MenuItems = [
    {
      label: getLocaleMessages({ id: "Logout" }),
      key: "logout",
    },
  ];

  const loginMenu = (
    <Menu
      key="logout"
      onClick={() => {
        dispatch({
          type: actions.LOGOUT_USER,
        });
      }}
      items={MenuItems}
    ></Menu>
  );
  const adminnotificationMenu = (
    <Menu style={{ overflow: "auto" }}>
      {notificationlist.length &&
        notificationlist.map((data) => {
          return (
            <Menu.Item key={data.id}>
              <a href={`/admin/Notificationview/${data.id}`}>
                {data.notification_content.en && data.notification_content.en}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const vendornotificationMenu = (
    <Menu style={{ overflow: "auto" }}>
      {notificationlist.length &&
        notificationlist.map((data) => {
          return (
            <Menu.Item key={data.id}>
              <a href={`/vendor/ViewNotification/${data.id}`}>
                {data.notification_content.en && data.notification_content.en}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <>
              {!isLoggedIn && (
                <>
                <div className="bottomNavResponsive">
                    <NavLink to={"/"}>
                      <i className="fa-solid fa-house"></i>
                      <span>Home</span>
                    </NavLink>
                
                    <NavLink to={"/vendor/login"}>
                      <i className="fa-solid fa-handshake"></i>
                      <span>{getLocaleMessages({ id: "For Partners" })}</span>
                    </NavLink>
                    <NavLink to={"/auth?type=signup"}>
                      <i className="fa-solid fa-user-plus"></i>
                      <span>{getLocaleMessages({ id: "Sign up" })}</span>
                    </NavLink>
                    <NavLink to={"/auth?type=login"}>
                    <i className="fa-solid fa-arrow-right-to-bracket"></i>
                      <span>{getLocaleMessages({ id: "Log in" })}</span>
                    </NavLink>
                    </div>
                </>
            )}

    


      <Layout.Header
        className={isLoggedIn ? "site-header site-header-admin" : "site-header"}
      >
        <div className="container">
          <div className="logo">
            <NavLink
              to={{
                pathname: ["admin", "vendor"].includes(getLocalDataType())
                  ? `/${getLocalDataType()}/dashboard`
                  : "/",
              }}
            >
              <img src={logoClolorPng} alt="" className="img-fluid" />
            </NavLink>

            {(location.pathname === getLocaleMessages({ id: "ListingBlank" }) ||
              location?.pathname
                ?.replace(/[^a-zA-Z0-9 ]/g, "")
                ?.replace(/[0-9]/g, "") === "details") && (
              <span className="location" onClick={CurrentLocationOffFun}>
                <span className="location_title">
                  {getLocaleMessages({ id: "Current Location" })}
                </span>
                <span className="location_address">
                  {formatted_address !== "undefined"
                    ? formatted_address !== ""
                      ? formatted_address
                      : getLocaleMessages({ id: "No location Selected" })
                    : locationName !== ""
                    ? locationName.replace("undefined", "")
                    : "القويعية,منطقة الرياض,Saudi Arabia"}
                </span>
                <i className="fa-solid fa-chevron-down"></i>
              </span>
            )}
          </div>

          {isLoggedIn && getLocalDataType() === "vendor" && (
            <div>
              {isprofileaccepted == 0 && (
                <p style={{ display: "inline", paddingLeft: "50px" }}>
                  <CloseCircleTwoTone />{" "}
                  <NavLink to={"/vendor/profile"}>
                    1. {getLocaleMessages({ id: "Please Update Profile" })}{" "}
                  </NavLink>
                </p>
              )}

              {isstaffaccepted == 0 && (
                <p style={{ display: "inline", paddingLeft: "50px" }}>
                  <CloseCircleTwoTone />{" "}
                  <NavLink to={"/vendor/staff"}>
                    {isprofileaccepted == 0 ? "2" : "1"}.{" "}
                    {getLocaleMessages({ id: "Please Create Staff" })} /{" "}
                    {getLocaleMessages({ id: "Staff time" })}
                  </NavLink>
                </p>
              )}

              {isserviceaccepted == 0 && (
                <p style={{ display: "inline", paddingLeft: "50px" }}>
                  <CloseCircleTwoTone />{" "}
                  <NavLink to={"/vendor/services"}>
                    {isprofileaccepted == 0 && isstaffaccepted == 0
                      ? "3"
                      : isprofileaccepted == 1 && isstaffaccepted == 1
                      ? "1"
                      : "2"}
                    . {getLocaleMessages({ id: "Please Create Menu" })}
                  </NavLink>
                </p>
              )}
            </div>
          )}
          <div className="menu-navigation">
            <ul>
              {!isLoggedIn && (
                <>
                  <li>
                    <NavLink to={"/vendor/login"}>
                      {getLocaleMessages({ id: "For Partners" })}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/auth?type=signup"}>
                      {getLocaleMessages({ id: "Sign up" })}
                    </NavLink>
                  </li>
                  <li className="login_a">
                    <NavLink to={"/auth?type=login"}>
                      {getLocaleMessages({ id: "Log in" })}
                    </NavLink>
                  </li>
                </>
              )}

              {isLoggedIn && getLocalDataType() === "vendor" && (
                <li>
                  <Dropdown
                    overlay={vendornotificationMenu}
                    overlayClassName="admin_notifiction"
                    placement="bottomRight"
                    trigger={["click"]}
                    arrow="true"
                    onClick={(e) => {
                      changeadminnotifiaction("vendor");
                    }}
                  >
                    <div className="user-loggin">
                      <Badge
                        count={
                          notificationcount > 10 ? "10+" : notificationcount
                        }
                        showZero
                      >
                        <i className="fa-solid fa-bell"></i>
                      </Badge>
                    </div>
                  </Dropdown>
                </li>
              )}
              {isLoggedIn && getLocalDataType() === "admin" && (
                <li>
                  <Dropdown
                    overlay={adminnotificationMenu}
                    overlayClassName="admin_notifiction"
                    placement="bottomRight"
                    trigger={["click"]}
                    arrow="true"
                    onClick={(e) => {
                      changeadminnotifiaction("admin");
                    }}
                  >
                    <div className="user-loggin">
                      <Badge
                        count={
                          notificationcount > 10 ? "10+" : notificationcount
                        }
                        showZero
                      >
                        <i className="fa-solid fa-bell"></i>
                      </Badge>
                    </div>
                  </Dropdown>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <Dropdown
                    overlay={loginMenu}
                    overlayClassName="extra-menu"
                    placement="bottomRight"
                    trigger={["click"]}
                    arrow="true"
                  >
                    {getLocalData("image_url") ? (
                      <div className="user-loggin">
                        <Avatar src={getLocalData("image_url")} />
                        <Text>
                          {getLocaleMessages({ id: "Hi" })},{" "}
                          {getLocalData("firstname")}
                        </Text>
                      </div>
                    ) : (
                      <div className="user-loggin">
                        <Avatar size={40} icon={<UserOutlined />} />
                        <Text>
                          {getLocaleMessages({ id: "Hi" })},{" "}
                          {getLocalData("firstname")}
                        </Text>
                      </div>
                    )}
                  </Dropdown>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Layout.Header>

      {/*Modal*/}
      <SignupModal
        visible={isModalVisible}
        onCancel={() => showModal({ signupModalVisible: false })}
        onFinish={onFinish}
        LoginSignup={LoginSignup}
        loader={loader}
      />

      <SigninModal
        visible={isModalLVisible}
        onCancel={() => showLModal({ loginModalVisible: false })}
        onLoginForgot={LoginForgot}
        onFinish={onFinishLogin}
        LoginSignup={LoginSignup}
        loader={loader}
      />

      <ForgotModal
        visible={isForgotVisible}
        onCancel={fOk}
        onFinish={onFinishForgot}
        onLoginForgot={LoginForgot}
        loader={loader}
      />

      <Modal
        title={false}
        open={isOtpVisible}
        onCancel={otpOk}
        centered
        footer={false}
        className="modal-ui-1 modal-otp"
        width="100%"
        destroyOnClose
      >
        <div className="modal-body-ui">
          <h2>{getLocaleMessages({ id: "OTP Verification" })}</h2>
          <p className="sub">
            {getLocaleMessages({ id: "Enter the OTP you received to" })} <br />{" "}
            {isemail}{" "}
          </p>
          <div className="ant-form-item-four">
            <OTP OTPdata={OTPdata} onChangeOTP={onChangeOTP} />
          </div>
          <div className="ant-form-item">
            <Button
              type="primary"
              disabled={loader}
              loading={loader}
              onClick={onFinishOTP}
            >
              {getLocaleMessages({ id: "Submit" })}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={getLocaleMessages({ id: "Current Location" })}
        open={isCurrentLocation}
        onCancel={CurrentLocationOffFun}
        centered
        footer={false}
        className="modal-current-location"
        width="100%"
        destroyOnClose
      >
        <div className="modal-body-forms">
          {(location.pathname === getLocaleMessages({ id: "ListingBlank" }) ||
            location?.pathname
              ?.replace(/[^a-zA-Z0-9 ]/g, "")
              ?.replace(/[0-9]/g, "") === "details") && (
            <div className="search-and-location">
              <Autocomplete
                className="custom-autocomplete"
                apiKey={"AIzaSyD2fIwEEQ7r4n9OSVvOBMblCVCxfz23aro"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                  }
                }}
                onPlaceSelected={(place) => {
                  localStorage.setItem(
                    "currentLocation",
                    JSON.stringify(place)
                  );
                  localStorage.setItem(
                    "formatted_address",
                    place.formatted_address
                  );
                  searchLocation(place);
                  SetIsCurrentLocation(false);
                }}
                defaultValue={
                  formatted_address !== null &&
                  formatted_address !== "undefined"
                    ? formatted_address
                    : storageLocation?.formatted_address !== "undefined"
                    ? storageLocation?.formatted_address
                    : locationName
                }
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default Header;
